import { Form, Grid, Input } from "@arco-design/web-react";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { UIInputNumber, UISelect } from "@/components/UI";
import AccountSelect from "@/components/AccountSelect";
import UOMSelect from "@/components/UOMSelect";
import VendorSelect from "@/components/VendorSelect";
import Status from "@/components/Status";
import { ItemAPI } from "@/apis";
import _ from "lodash";
import Decimal from "decimal.js";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

export const ItemForm = forwardRef(({ value }, ref) => {
    const [form] = Form.useForm();
    const [uomName, setUOMName] = useState();
    const [uoms, setUOMs] = useState([]);
    const [itemType, setItemType] = useState();
    const [cogsAccountLabel, setCogsAccountLabel] = useState();

    const onUOMChange = useCallback((val) => {
        if (val) {
            setUOMName(_.find(uoms, item => item.id === val).name);
        } else {
            setUOMName(null);
        }
    }, [uoms]);

    const onItemTypeChange = (value) => {
        setItemType(value);
        setCogsAccountLabel(value === 2 ? "COGS/Expense Account" : "COGS Account");
    }

    const onSalePriceChange = (val) => {
        const cost = form.getFieldValue("stdCost");
        const margin = calcMargin(cost, val);
        if (margin == null) return;
        form.setFieldsValue({ defaultMargin: margin, defaultMarginPercent: margin * 100 });
    }

    const onMarginChange = (val) => {
        const cost = form.getFieldValue("stdCost");
        if (cost == null || val == null || val === 1) return;

        const price = new Decimal(cost / (1 - val)).toDecimalPlaces(5).toNumber();
        form.setFieldsValue({ salePrice: price });
    }

    const calcMargin = (cost, price) => {
        if (cost == null || price == null || price === 0) return null;

        const margin = new Decimal((price - cost) / price).toDecimalPlaces(5).toNumber();
        return margin;
    }

    const getValues = async () => {
        return await form.validate();
    }

    useImperativeHandle(ref, () => ({
        getValues
    }));

    useEffect(() => {
        const init = async () => {
            form.resetFields();

            const uoms = await ItemAPI.uoms();
            setUOMs(uoms);

            let values = { ...value };

            if (values.unit) {
                setUOMName(_.find(uoms, item => item.id === values.unit).name);
            }

            onItemTypeChange(values.itemType);

            values.defaultMargin = calcMargin(values.stdCost, values.salePrice);
            if (values.defaultMargin != null) {
                values.defaultMarginPercent = values.defaultMargin * 100;
            }

            form.setFieldsValue(values);
        }
        init();
    }, [form, value]);

    return (
        <Form form={form} colon
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 15 }}
        >
            <FormItem field="id" hidden>
                <Input />
            </FormItem>
            {value.id &&
                <FormItem field="status" label="Status" required rules={[{ required: true }]}>
                    <Status.Select />
                </FormItem>
            }
            <FormItem field="itemType" label="Item Type" required rules={[{ required: true }]}>
                <UISelect.DictSelect type="item-type" onChange={onItemTypeChange} />
            </FormItem>
            <FormItem field="itemNo" label="Item Number" required rules={[{ required: true }]}>
                <Input />
            </FormItem>
            <FormItem field="itemName" label="Item Name" required rules={[{ required: true }]}>
                <Input />
            </FormItem>
            <FormItem field="description" label="Description">
                <TextArea />
            </FormItem>
            <FormItem field="stdCost" label="Standard Cost">
                <UIInputNumber precision={5} onChange={val => {
                    const margin = form.getFieldValue("defaultMargin");
                    const price = form.getFieldValue("salePrice");
                    if (margin != null) {
                        onMarginChange(margin);
                    } else if (price != null) {
                        onSalePriceChange(price);
                    }
                }} />
            </FormItem>
            <FormItem field="unit" label="Unit of Measure(UOM)" required rules={[{ required: true }]}>
                <UOMSelect onChange={onUOMChange} />
            </FormItem>
            <FormItem field="defaultMarginPercent" label="Default Margin">
                <UIInputNumber precision={3} onChange={val => {
                    const margin = val / 100;
                    form.setFieldValue("defaultMargin", margin);
                    onMarginChange(margin);
                }} suffix="%" />
            </FormItem>
            <FormItem field="defaultMargin" hidden>
                <Input />
            </FormItem>
            <FormItem field="salePrice" label="Sales Price">
                <UIInputNumber precision={5} onChange={val => onSalePriceChange(val)} />
            </FormItem>
            <FormItem field="cogsAccountId" label={cogsAccountLabel} required rules={[{ required: true }]}>
                {itemType === 2 ?
                    <AccountSelect category={2} />
                    :
                    <AccountSelect type="3" />
                }
            </FormItem>
            <FormItem field="incomeAccountId" label="Income Account" required rules={[{ required: true }]}>
                <AccountSelect type="1" />
            </FormItem>
            {itemType === 1 &&
                <FormItem field="inventoryAccountId" label="Inventory Account" required rules={[{ required: true }]}>
                    <AccountSelect type="7" />
                </FormItem>
            }
            <FormItem label="Safety Stock Level(UOM)">
                {() => {
                    if (uomName) {
                        return (
                            <FormItem field="safetyStockLevelUom">
                                <UIInputNumber suffix={uomName} />
                            </FormItem>
                        )
                    }
                    return (
                        <FormItem field="safetyStockLevel">
                            <UIInputNumber suffix="days" />
                        </FormItem>
                    )
                }}
            </FormItem>
            <FormItem label="Preferred Vendor" style={{ marginBottom: 0 }}>
                <Grid.Row gutter={16}>
                    <Grid.Col span={12}>
                        <FormItem field="preferredVendorId">
                            <VendorSelect />
                        </FormItem>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <FormItem field="preferredVendorLeadTime" labelAlign="left" label="Lead Time(days)">
                            <Input />
                        </FormItem>
                    </Grid.Col>
                </Grid.Row>
            </FormItem>
        </Form>
    )
})