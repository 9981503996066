import { Button, Divider, Form, Grid, Input, Space } from "@arco-design/web-react";
import { UISelect, UIInputNumber, UIDatePicker } from "@/components/UI";
import { IconDelete, IconPlus } from "@arco-design/web-react/icon";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { ItemAPI, CompanyAPI, VendorAPI, CustomerAPI, POAPI } from "@/apis";
import VendorSelect from "@/components/VendorSelect";
import UOMSelect from "@/components/UOMSelect";
import Address from "@/components/Address";
import _ from "lodash";
import { Link } from "react-router-dom";

const Row = Grid.Row;
const Col = Grid.Col;
const FormItem = Form.Item;
const TextArea = Input.TextArea;

export const POForm = forwardRef((props, ref) => {
    const { value, ...restProps } = props;

    const [form] = Form.useForm();
    const [items, setItems] = useState([]);
    const [shipAddresses, setShipAddresses] = useState([]);
    const [customers, setCustomers] = useState([]);

    useImperativeHandle(ref, () => ({
        getFormValues
    }));

    const afterVendorSelected = async (vendorId) => {
        const vendorInfo = await VendorAPI.get(vendorId);
        form.setFieldValue('term', vendorInfo.term);
    }

    const getFormValues = async () => {
        const values = await form.validate();
        return values;
    }

    const getCustomers = useCallback(async () => {
        const values = await CustomerAPI.actives();
        let customers = _.sortBy(values, "customerName");
        setCustomers(customers);
    }, []);

    const getAddresses = useCallback(async (dropShip) => {
        let addresses = [];
        if (dropShip) {
            const customer = await CustomerAPI.get(dropShip);
            addresses = _.concat(addresses, customer.shipAddresses);
        } else {
            const companyShipToList = await CompanyAPI.addresses();
            addresses = _.concat(addresses, companyShipToList);
        }

        addresses = _.sortBy(addresses, "contactName");
        setShipAddresses(addresses);
        if (_.size(addresses) > 0) {
            const addr = addresses[0];
            form.setFieldValue("shipToId", addr.id);
            form.setFieldValue("shipToContactName", addr.contactName);
            form.setFieldValue("shipToAddress", addr.address);
            form.setFieldValue("shipToCity", addr.city);
            form.setFieldValue("shipToState", addr.state);
            form.setFieldValue("shipToZip", addr.zip);
        }

    }, [form]);

    const calcAmount = (index) => {
        const field = "items[" + index + "]";
        const quantity = form.getFieldValue(field + ".quantity");
        const rate = form.getFieldValue(field + ".rate");
        if (quantity && rate) {
            const amount = quantity * rate;
            form.setFieldValue(field + ".amount", amount);
        }
        calcTotalAmount();
    }

    const calcTotalAmount = () => {
        const items = form.getFieldValue("items");
        const total = _.sumBy(items, o => o.amount ?? 0);
        form.setFieldValue("totalAmount", total);
    }

    useEffect(() => {
        const getItems = async () => {
            let values = await ItemAPI.actives();
            values = _.sortBy(values, "itemName");
            setItems(values);
        }

        getItems();
    }, []);

    useEffect(() => {
        const load = async () => {
            form.clearFields();
            getCustomers();
            await getAddresses(value.dropShip);
            form.setFieldsValue(value);
        }

        load();
    }, [getCustomers, getAddresses, form, value])

    return (
        <Form form={form} colon
            {...restProps}
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}>
            <FormItem field="id" hidden>
                <Input />
            </FormItem>
            <FormItem>
                <Row gutter={8}>
                    <Col span={6}>
                        <FormItem label="Vendor" field="vendorId"
                            labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                            required rules={[{ required: true }]}>
                            <VendorSelect onChange={val => afterVendorSelected(val)} />
                        </FormItem>
                    </Col>
                    <Col span={10}>
                        <FormItem label="Drop Ship" field="dropShip" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <UISelect records={customers} labelKey="customerName" allowClear
                                showSearch
                                onChange={val => {
                                    getAddresses(val);
                                }} />
                        </FormItem>
                        <FormItem label="Ship To" field="shipToContactName" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                            required rules={[{ required: true }]}>
                            <Address.Select
                                dropdownRender={(menu) => (
                                    <div>
                                        {menu}
                                        <Divider style={{ marginTop: 0, marginBottom: 10 }} />
                                        <Link to={`/workspace/company/profile`} style={{ marginLeft: 20 }}>Add New</Link>
                                    </div>
                                )}
                                addresses={shipAddresses} onAddrChange={val => {
                                    form.setFieldValue("shipToAddress", val.address);
                                    form.setFieldValue("shipToCity", val.city);
                                    form.setFieldValue("shipToState", val.state);
                                    form.setFieldValue("shipToZip", val.zip);
                                }} />
                        </FormItem>
                        <FormItem field="shipToAddress" hidden>
                            <Input />
                        </FormItem>
                        <FormItem field="shipToCity" hidden>
                            <Input />
                        </FormItem>
                        <FormItem field="shipToState" hidden>
                            <Input />
                        </FormItem>
                        <FormItem field="shipToZip" hidden>
                            <Input />
                        </FormItem>
                        <FormItem shouldUpdate noStyle>
                            {(value) => {
                                return (
                                    <FormItem wrapperCol={{ offset: 8 }}>
                                        <Address value={{
                                            name: value.shipToContactName,
                                            address: value.shipToAddress,
                                            city: value.shipToCity,
                                            state: value.shipToState,
                                            zip: value.shipToZip,
                                        }} />
                                    </FormItem>
                                )
                            }}
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label="PO Date" field="poDate" labelCol={{ span: 9 }} required rules={[{ required: true }]}>
                            <UIDatePicker />
                        </FormItem>
                        <FormItem label="Due Date" field="dueDate" labelCol={{ span: 9 }} required rules={[{ required: true }]}>
                            <UIDatePicker />
                        </FormItem>
                        <FormItem label="Term" field="term" labelCol={{ span: 9 }}>
                            <UISelect.DictSelect type="term" />
                        </FormItem>
                        <FormItem label="Total Amount" field="totalAmount" labelCol={{ span: 9 }}>
                            <UIInputNumber readOnly />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormItem label="Reference" labelCol={{ span: 2 }}>
                            <Form.List field="reference">
                                {(fields, { add, remove, move }) =>
                                    <>
                                        {fields.map((item, index) => {
                                            return (
                                                <FormItem key={item.key} style={{ marginBottom: 0 }}>
                                                    <Space className="form-item-row">
                                                        <FormItem field={item.field + ".id"} hidden>
                                                            <Input />
                                                        </FormItem>
                                                        <FormItem field={item.field + ".type"}>
                                                            <UISelect.DictSelect type="reference-type" order="label" style={{ width: 200 }} showSearch />
                                                        </FormItem>
                                                        <FormItem field={item.field + ".number"}>
                                                            <Input />
                                                        </FormItem>
                                                        <Button type="text" status="danger" icon={<IconDelete />} onClick={() => remove(index)}></Button>
                                                    </Space>
                                                </FormItem>
                                            )
                                        })}
                                        <Button type="text" icon={<IconPlus />} onClick={() => add()}>Add Reference</Button>
                                    </>
                                }
                            </Form.List>
                        </FormItem>
                        <FormItem label="Memo" labelCol={{ span: 2 }} field="memo">
                            <TextArea />
                        </FormItem>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="Items" labelCol={{ span: 2 }}>
                <Form.List field="items">
                    {(fields, { add, remove, move }) =>
                        <>
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th width="120">Item Number</th>
                                        <th>Item Name</th>
                                        <th>Description</th>
                                        <th>Qty</th>
                                        <th>U/M</th>
                                        <th>Rate</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <span>#{index + 1}</span>
                                                    <FormItem field={item.field + ".id"} hidden>
                                                        <Input />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".itemNo"} required noStyle>
                                                        <Input readOnly />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".itemId"} required rules={[{ required: true }]} noStyle>
                                                        <UISelect showSearch records={items} labelKey="itemName" style={{ minWidth: 120 }}
                                                            onChange={async (val) => {
                                                                const vendorId = form.getFieldValue("vendorId");
                                                                const poItem = await POAPI.getVendorLastItem(vendorId, val);
                                                                const itemObj = _.find(items, item => item.id === val);
                                                                form.setFieldValue(`${item.field}.itemNo`, itemObj.itemNo);
                                                                form.setFieldValue(`${item.field}.itemName`, itemObj.itemName);
                                                                form.setFieldValue(`${item.field}.description`, itemObj.description);
                                                                form.setFieldValue(`${item.field}.unit`, itemObj.unit);
                                                                form.setFieldValue(`${item.field}.rate`, poItem ? poItem.rate : itemObj.stdCost);
                                                            }} />
                                                    </FormItem>
                                                    <FormItem field={item.field + ".itemName"} hidden>
                                                        <Input />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".description"} noStyle>
                                                        <Input />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".quantity"} required rules={[{ required: true }]} noStyle>
                                                        <UIInputNumber onBlur={() => calcAmount(index)} />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".unit"} rules={[{ required: true }]} noStyle>
                                                        <UOMSelect disabled />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".rate"} required rules={[{ required: true }]} noStyle>
                                                        <UIInputNumber onBlur={() => calcAmount(index)} />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".amount"} required rules={[{ required: true }]} noStyle>
                                                        <UIInputNumber />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem noStyle>
                                                        <Button type="text" status="danger" icon={<IconDelete />}
                                                            onClick={() => {
                                                                remove(index);
                                                                calcAmount();
                                                            }}></Button>
                                                    </FormItem>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <FormItem>
                                <Button type="text" icon={<IconPlus />} onClick={() => add()}>Add Item</Button>
                            </FormItem>
                        </>
                    }
                </Form.List>
            </FormItem>
        </Form>
    );
})