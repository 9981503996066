import { Button, Form, Grid, Input, Space } from "@arco-design/web-react";
import { UISelect, UIInputNumber, UIDatePicker } from "@/components/UI";
import CustomerSelect from "@/components/CustomerSelect";
import UOMSelect from "@/components/UOMSelect";
import Address from "@/components/Address";
import { IconDelete, IconPlus } from "@arco-design/web-react/icon";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { ItemAPI, CustomerAPI, SOAPI } from "@/apis";
import _ from "lodash";

const Row = Grid.Row;
const Col = Grid.Col;
const FormItem = Form.Item;
const TextArea = Input.TextArea;

export const SaleOrderForm = forwardRef((props, ref) => {
    const { value } = props;

    const [form] = Form.useForm();
    const [items, setItems] = useState([]);
    const [shipAddresses, setShipAddresses] = useState([]);

    const getFormData = async () => {
        return await form.validate();
    }

    const getAddresses = useCallback(async (customerId) => {
        const values = await CustomerAPI.addresses(customerId);
        const addresses = _.sortBy(values, "contactName");
        setShipAddresses(addresses);
        if (_.size(values) > 0) {
            const val = values[0];
            form.setFieldValue("shipToId", val.id);
            form.setFieldValue("shipToContactName", val.contactName);
            form.setFieldValue("shipToAddress", val.address);
            form.setFieldValue("shipToCity", val.city);
            form.setFieldValue("shipToState", val.state);
            form.setFieldValue("shipToZip", val.zip);
        }
    }, [form]);

    const afterCustomerSelected = async (val) => {
        const customer = await CustomerAPI.get(val);
        form.setFieldValue("term", customer.term);
        getAddresses(val);
    }

    const calcAmount = (index) => {
        const field = "items[" + index + "]";
        const quantity = form.getFieldValue(field + ".quantity");
        const rate = form.getFieldValue(field + ".rate");
        if (quantity && rate) {
            const amount = quantity * rate;
            form.setFieldValue(field + ".amount", amount);
        }
        calcTotal();
    }

    const calcTotal = () => {
        let items = form.getFieldValue("items");
        let total = _.sumBy(items, o => o.amount ?? 0);
        form.setFieldValue("totalAmount", total);
    }

    useEffect(() => {
        const getItems = async () => {
            const values = await ItemAPI.actives();
            let items = _.sortBy(values, "itemName");
            setItems(items);
        }

        getItems();
    }, []);

    useEffect(() => {
        const load = async () => {
            form.clearFields();
            if (value.customerId) {
                await getAddresses(value.customerId);
            }
            form.setFieldsValue(value);
        }
        load();
    }, [form, value, getAddresses]);

    useImperativeHandle(ref, () => ({
        getFormData
    }));

    return (
        <Form form={form} colon
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}>
            <FormItem field="id" hidden>
                <Input />
            </FormItem>
            <FormItem>
                <Row gutter={8}>
                    <Col span={8}>
                        <FormItem label="Customer" field="customerId" required rules={[{ required: true }]}>
                            <CustomerSelect onChange={val => afterCustomerSelected(val)} />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label="Ship To" field="shipToContactName" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                            required rules={[{ required: true }]}>
                            <Address.Select addresses={shipAddresses} onAddrChange={val => {
                                form.setFieldValue("shipToContactName", val.contactName);
                                form.setFieldValue("shipToAddress", val.address);
                                form.setFieldValue("shipToCity", val.city);
                                form.setFieldValue("shipToState", val.state);
                                form.setFieldValue("shipToZip", val.zip);
                            }} />

                        </FormItem>
                        <FormItem field="shipToAddress" hidden>
                            <Input />
                        </FormItem>
                        <FormItem field="shipToCity" hidden>
                            <Input />
                        </FormItem>
                        <FormItem field="shipToState" hidden>
                            <Input />
                        </FormItem>
                        <FormItem field="shipToZip" hidden>
                            <Input />
                        </FormItem>
                        <FormItem shouldUpdate noStyle>
                            {(value) => {
                                return (
                                    <FormItem wrapperCol={{ offset: 8, span: 16 }}>
                                        <Address value={{
                                            name: value.shipToContactName,
                                            address: value.shipToAddress,
                                            city: value.shipToCity,
                                            state: value.shipToState,
                                            zip: value.shipToZip,
                                        }} />
                                    </FormItem>
                                )
                            }}
                        </FormItem>

                    </Col>
                    <Col span={8}>
                        <FormItem label="SO Date" field="soDate" labelCol={{ span: 8 }} required rules={[{ required: true }]}>
                            <UIDatePicker />
                        </FormItem>
                        <FormItem label="Due Date" field="dueDate" labelCol={{ span: 8 }} required rules={[{ required: true }]}>
                            <UIDatePicker />
                        </FormItem>
                        <FormItem label="Term" field="term" labelCol={{ span: 8 }}>
                            <UISelect.DictSelect type="term" />
                        </FormItem>
                        <FormItem label="SO Total" field="totalAmount">
                            <UIInputNumber readOnly />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormItem label="Reference" labelCol={{ span: 2 }}>
                            <Form.List field="reference">
                                {(fields, { add, remove, move }) =>
                                    <>
                                        {fields.map((item, index) => {
                                            return (
                                                <FormItem key={item.key} style={{ marginBottom: 0 }}>
                                                    <Space className="form-item-row">
                                                        <FormItem field={item.field + ".type"}>
                                                            <UISelect.DictSelect type="reference-type" order="label" style={{ width: 200 }} showSearch />
                                                        </FormItem>
                                                        <FormItem field={item.field + ".number"}>
                                                            <Input />
                                                        </FormItem>
                                                        <Button type="text" status="danger" icon={<IconDelete />} onClick={() => remove(index)}></Button>
                                                    </Space>
                                                </FormItem>
                                            )
                                        })}
                                        <Button type="text" icon={<IconPlus />} onClick={() => add()}>Add Reference</Button>
                                    </>
                                }
                            </Form.List>
                        </FormItem>
                        <FormItem label="Memo" labelCol={{ span: 2 }} field="memo">
                            <TextArea />
                        </FormItem>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="Items" labelCol={{ span: 2 }}>
                <Form.List field="items">
                    {(fields, { add, remove, move }) =>
                        <>
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th width="120">Item Number</th>
                                        <th>Item Name</th>
                                        <th>Description</th>
                                        <th>Qty</th>
                                        <th>U/M</th>
                                        <th>Rate</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <span>#{index + 1}</span>
                                                    <FormItem field={item.field + ".id"} hidden>
                                                        <Input />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".itemNo"} noStyle>
                                                        <Input readOnly />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".itemId"} required rules={[{ required: true }]} noStyle>
                                                        <UISelect records={items}
                                                            labelKey="itemName"
                                                            style={{ minWidth: 120 }}
                                                            showSearch
                                                            onChange={async (val) => {
                                                                const value = _.find(items, item => item.id === val);
                                                                let rate = value.salePrice;
                                                                const customerId = form.getFieldValue("customerId");
                                                                if (customerId) {
                                                                    const soItem = await SOAPI.getCustomerLastSOItem(customerId, val);
                                                                    if (soItem) {
                                                                        rate = soItem.rate;
                                                                    }
                                                                }
                                                                form.setFieldValue(item.field + ".itemNo", value.itemNo);
                                                                form.setFieldValue(item.field + ".itemName", value.itemName);
                                                                form.setFieldValue(item.field + ".description", value.description);
                                                                form.setFieldValue(item.field + ".unit", value.unit);
                                                                form.setFieldValue(item.field + ".rate", rate);
                                                                calcAmount(index);
                                                            }} />
                                                    </FormItem>
                                                    <FormItem field={item.field + ".itemName"} hidden>
                                                        <Input />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".description"} noStyle>
                                                        <Input />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".quantity"} required rules={[{ required: true }]} noStyle>
                                                        <UIInputNumber onBlur={() => calcAmount(index)} />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".unit"} rules={[{ required: true }]} noStyle>
                                                        <UOMSelect disabled />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".rate"} required rules={[{ required: true }]} noStyle>
                                                        <UIInputNumber onBlur={() => calcAmount(index)} />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".amount"} required rules={[{ required: true }]} noStyle>
                                                        <UIInputNumber />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem noStyle>
                                                        <Button type="text" status="danger" icon={<IconDelete />}
                                                            onClick={() => {
                                                                remove(index);
                                                                calcTotal();
                                                            }}></Button>
                                                    </FormItem>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <FormItem>
                                <Button type="text" icon={<IconPlus />} onClick={() => add()}>Add Item</Button>
                            </FormItem>
                        </>
                    }
                </Form.List>
            </FormItem>
        </Form>
    );
})